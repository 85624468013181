import '../sass/main.scss';

import LazyLoad from 'vanilla-lazyload';
import MobileDetect from 'mobile-detect';
import lightbox from 'lightbox2';
import $ from 'jquery';
window.$ = window.jQuery = $;
import 'slick-carousel';

var Odometer = require('./odometer.js');

var siteGlobal = {
  initReady: function () {
  	this.mobileDetect();
  },
  initLoad: function () {
    this.customSelect();
  	this.lazyLoad();
    this.menuInit();
    this.homeVisualGal();
    this.homeAboutGal();
    this.homeElsGal();
    this.qualitaGal();
    this.timelineInit();
    this.odometerInit();
    this.divisioniGal();
    this.prodGal();
    this.vantaggiSlider();
    this.vantOdometerInit();
  },
  initScroll: function(){
  },
  mobileDetect: function() {
    var detector = new MobileDetect(window.navigator.userAgent);
  	if (detector.mobile()){
      $('html').addClass('is-touch');
    } else {
      $('html').addClass('no-touch');
    }
  },
  lazyLoad: function(){
    var ll = new LazyLoad({
        elements_selector: '.lazy',
        use_native: true
    })
  },
  menuInit: function(){
    $('.header__hamburger').on('click', function(){
      $('.header__hamburger, .header__menu').toggleClass('open');
    })
  },
  homeVisualGal: function(){
    if ($('.homeVisual__gall').length) {
    	$('.homeVisual__gall').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
        speed: 600,
        variableWidth: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3500
      });

      /* Cambio numero progressivo slide */
      $('.homeVisual__gall').on('afterChange', function(event, slick, currentSlide, nextSlide){
        var i = (currentSlide ? currentSlide : 0) + 1;
        $('.homeVisual').find('.homeVisual__page').html('<span>0' + i + '</span> /0' + slick.slideCount);
      });

      /* Cambio progressione barra laterale delle slide */
      $('.homeVisual__gall').on('beforeChange', function(event, slick, currentSlide, nextSlide){
        var bar = 100 / (currentSlide + 1);
        $('.homeVisual').find('.homeVisual__pagingBar span').animate({
          width: bar+'%'
        }, 250);
      });
    }
  },
  homeAboutGal: function(){
    if ($('.homeAz__gall').length) {
      $('.homeAz__gall').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        asNavFor: '.homeAz__names',
        autoplay: true,
        autoplaySpeed: 3500,
        responsive: [{
          breakpoint: 767,
          settings: {
            asNavFor: false
          }
        }]
      });

      if ($(window).outerWidth() > 767) {       
        $('.homeAz__names').slick({
          slidesToShow: 3,
          slidesToScroll: 1,
          asNavFor: '.homeAz__gall',
          dots: false,
          focusOnSelect: true
        });
      }
    }
  },
  homeElsGal: function(){
    if ($('.homeElBlock').length) {
      $('.homeElBlock').slick({
        vertical: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
        speed: 600,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3500,
        responsive: [{
          breakpoint: 767,
          settings: {
            vertical: false
          }
        }]
      });
      /* Cambio numero progressivo slide */
      $('.homeElBlock').on('afterChange', function(event, slick, currentSlide, nextSlide){
        var i = (currentSlide ? currentSlide : 0) + 1;
        $(this).parent().find('.homeVisual__page').html('<span>0' + i + '</span> /0' + slick.slideCount);
      });
    }
  },
  qualitaGal: function(){
    if ($('.azVal__gall').length) {
      $('.azVal__gall').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 3500
      });
    }
  },
  odometerInit: function(){
    let data = $('[data-slick-index="0"]').find('.tl__imgBlock').data('num');
    if ($('.tl').find('#data').length) {
      var od = new Odometer({
            el: $('.tl').find('#data')[0],
            duration: 300,
            value: data,
            format: 'd',
            theme: 'default'
      });
    }

    /* Aggiornamento data */
    $('.tl__slider').on('beforeChange', function(event, slick, currentSlide, nextSlide){
        let data = $('[data-slick-index="'+nextSlide+'"]').find('.tl__imgBlock').data('num');
        if ($('.tl').find('#data').length) {
          od.update(data);
        }
    });
  },
  customSelect: function(){
    $('.wpcf7 select').each(function(){
        var $this = $(this), 
          numberOfOptions = $(this).children('option').length,
          classList = $(this).attr('class').split(',');;
      
        $this.addClass('select-hidden'); 
        $this.wrap('<div class="select"></div>');
        $this.after('<div class="select-styled"></div>');

        var $styledSelect = $this.next('div.select-styled');
        $styledSelect.text($this.children('option').eq(0).text());
      
        var $list = $('<ul />', {
            'class': 'select-options ' + classList
        }).insertAfter($styledSelect);
      
        for (var i = 0; i < numberOfOptions; i++) {
            $('<li />', {
                text: $this.children('option').eq(i).text(),
                rel: $this.children('option').eq(i).val()
            }).appendTo($list);
        }
      
        var $listItems = $list.children('li');
      
        $styledSelect.on('click', function(e) {
            e.stopPropagation();
            $(this).toggleClass('active').next('ul.select-options').slideToggle(150);
            $('div.select-styled.active').not(this).each(function(){
                $(this).removeClass('active').next('ul.select-options').hide();
            });
        });
      
        $listItems.on('click', function(e) {
            e.stopPropagation();
            $styledSelect.text($(this).text()).removeClass('active');
            $this.val($(this).attr('rel'));
            $list.hide();
            $(this).parent().siblings('.select-styled').addClass('sel');
        });
    });
  },
  timelineInit: function(){
    if ($('.tl').length) {
      $('.tl__slider').slick({
        speed: 600,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: '.tl__list',
        draggable: false,
        touchMove: false,
        autoplay: true,
        autoplaySpeed: 4500
      });
      $('.tl__list').slick({
        speed: 600,
        slidesToShow: 5,
        slidesToScroll: 1,
        asNavFor: '.tl__slider',
        arrows: true,
        dots: false,
        focusOnSelect: true,
        vertical: true
      });

      $('.slick-current').addClass('app');

      $('.tl__slider').on('beforeChange', function(event, slick, currentSlide, nextSlide){
        let currSlide = $('[data-slick-index="'+currentSlide+'"]');
          $(currSlide).addClass('moving');
      });

      $('.tl__slider').on('afterChange', function(event, slick, currentSlide, nextSlide){
        $('.app').removeClass('app');
        let currSlide = $('[data-slick-index="'+currentSlide+'"]');
        $(currSlide).addClass('app');
        $('.moving').removeClass('moving');
      });
    }
  },
  divisioniGal: function(){
    if ($('.cpGal').length) {
      $('.cpGal').each(function(){
        $(this).slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          autoplay: true,
          autoplaySpeed: 3500
        });
      })
    }
  },
  prodGal: function(){
    if ($('.realGal').length) {
      $('.realGal').each(function(){
        $(this).slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          arrows: true,
          autoplay: true,
          autoplaySpeed: 3500
        });
      })
    }
  },
  vantOdometerInit: function(){
    if ($('.vantTl').find('#data').length) {
      let data = $('[data-slick-index="0"]').find('.vtl__imgBlock').data('num');
      const vantOd = new Odometer({
            el: $('.vantTl').find('#data')[0],
            value: data,
            format: 'd',
            theme: 'default'
      });

      /* Aggiornamento data */
      $('.vtl__slider').on('beforeChange', function(event, slick, currentSlide, nextSlide){
          let data = $('[data-slick-index="'+nextSlide+'"]').find('.vtl__imgBlock').data('num');
          if ($('.vtl').find('#data').length) {
            vantOd.update(data);
          }
      });
    }
  },
  vantaggiSlider: function(){
    if ($('.vtl').length) {
      var slidesNum = $('.vtl__listEl').length;
      $('.vantTl__title > span').text(slidesNum);
      
      // if ($(window).outerWidth() > 767) {
        $('.vtl__slider').slick({
          speed: 600,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          fade: true,
          asNavFor: '.vtl__list',
          draggable: false,
          touchMove: false,
          autoplay: true,
          autoplaySpeed: 4500
        });
      // }

      $('.vtl__list').slick({
        speed: 600,
        slidesToShow: slidesNum,
        slidesToScroll: 1,
        asNavFor: '.vtl__slider',
        arrows: true,
        dots: false,
        focusOnSelect: true,
        vertical: true,
        responsive: [{
          breakpoint: 767,
          settings: {
            asNavFor: false,
            arrows: false
          }
        }]
      });

      $('.slick-current').addClass('app');

      $('.vtl__slider').on('beforeChange', function(event, slick, currentSlide, nextSlide){
        let currSlide = $('[data-slick-index="'+currentSlide+'"]');
          $(currSlide).addClass('moving');
      });

      $('.vtl__slider').on('afterChange', function(event, slick, currentSlide, nextSlide){
        $('.app').removeClass('app');
        let currSlide = $('[data-slick-index="'+currentSlide+'"]');
        $(currSlide).addClass('app');
        $('.moving').removeClass('moving');
      });


    }
  },
  lightboxInit: function(){
      lightbox.init();
      lightbox.option({
        'resizeDuration': 100,
        'wrapAround': true,
        'albumLabel': '',
        'imageFadeDuration': 300
      })
  },
  rebuildAllEvents: function(){
    this.initLoad();
    this.lightboxInit();
  },
};

global.siteGlobal = siteGlobal;


(function () {
  siteGlobal.initReady();
  $( window ).on('load', function() {
    siteGlobal.initLoad();
  });
  $(window).on('scroll', function(){
    siteGlobal.initScroll();
  })
}());